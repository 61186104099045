import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SearchState,
  ISearchRequestPayload,
  IGetEmptyCampsRequestPayload,
  ICampAdRequestPayload,
  IGetSearchCampsRequest,
  IGetCampSearchCampRequestPayload,
} from 'store/types';
import {
  ICamp,
  ICampAdvertisement,
  ISearchResultCamp,
  IZoneSiteExhibitionCamp,
} from '@types';

const initialState: SearchState = {
  campList: [],
  campAdList: [],
  campsCnt: 0,
  error: '',
  hasMore: true,
  offset: 0,
  longTermOffset: 0,
  popPath: '',
  emptyCamps: [],
  emptyCampsOffset: 0,
  hasMoreEmptyCamps: true,
  emptyCampsCnt: 0,
  sortedType: 'recommendDesc',

  emptySites: [],
  emptySitesOffset: 0,
  hasMoreEmptySites: true,

  searchResultCamps: undefined,
  searchAdList: undefined,
  searchResultOffset: 0,
  hasMoreSearchResult: true,
  searchResultCount: undefined,
  isLongtermCampListLoading: false,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchRequest: (state, action: PayloadAction<ISearchRequestPayload>) => {
      action.payload.skip ? state.campList : (state.campList = []);
      state.error = '';
      state.sortedType =
        action.payload.sort === undefined
          ? 'recommendDesc'
          : action.payload.sort;
    },

    searchSuccess: (state, action: PayloadAction<ICamp[]>) => {
      if (action.payload.length)
        state.campList = state.campList.concat(action.payload);
      state.hasMore = action.payload.length === 10;
      state.error = '';
    },

    searchFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    longTermSearchRequest: (
      state,
      action: PayloadAction<IGetSearchCampsRequest>,
    ) => {
      if (action.payload.skip !== undefined) {
        state.searchResultOffset = action.payload.skip;
        if (action.payload.skip === 0) state.searchResultCamps = [];
      } else {
        state.searchResultCamps = [];
      }
      // action.payload.page !== 1 ? state.campList : (state.campList = []);
      // state.isLongtermCampListLoading = true;
    },

    longTermSearchSuccess: (
      state,
      action: PayloadAction<{ data: ISearchResultCamp[]; hasNext: boolean }>,
    ) => {
      if (action.payload.data.length)
        state.searchResultCamps = (state.searchResultCamps || []).concat(
          action.payload.data,
        );
      state.hasMoreSearchResult = action.payload.hasNext;
      state.error = '';
    },

    longTermSearchFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getCampAdRequest: (state, action: PayloadAction<ICampAdRequestPayload>) => {
      state.error = '';
      state.sortedType =
        action.payload.sort === undefined
          ? 'recommendDesc'
          : action.payload.sort;
    },

    getCampAdSuccess: (state, action: PayloadAction<ICampAdvertisement[]>) => {
      state.campAdList = action.payload;
      state.error = '';
    },

    getCampAdFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    searchCamps: (
      state,
      action: PayloadAction<
        Partial<ISearchRequestPayload> | IGetEmptyCampsRequestPayload
      >,
    ) => {
      state.offset = 0;
      state.emptyCampsOffset = 0;
      state.hasMore = true;
    },

    resetSearchCampsState: state => {
      state.offset = 0;
      state.emptyCampsOffset = 0;
      state.hasMore = true;
    },

    getCampsCountRequest: (state, action) => {},

    getCampsCountSuccess: (state, action: PayloadAction<number>) => {
      state.campsCnt = action.payload;
    },

    getCampsCountFailure: (state, action: PayloadAction<Error | string>) => {
      state.campsCnt = 0;
      state.error = action.payload;
    },

    longTermSearchCountRequest: (state, action) => {},

    longTermSearchCountSuccess: (
      state,
      action: PayloadAction<{ data: { count: number } }>,
    ) => {
      state.searchResultCount = action.payload.data.count;
    },

    longTermSearchCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.searchResultCount = undefined;
    },

    addOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    addAutoOffset: state => {
      state.offset += 10;
    },

    checkPopPath: (state, action: PayloadAction<string>) => {
      state.popPath = action.payload;
    },

    getEmptyCampsRequest: (
      state,
      action: PayloadAction<IGetEmptyCampsRequestPayload>,
    ) => {
      action.payload.skip ? state.emptyCamps : (state.emptyCamps = []);
      state.error = '';
    },

    getEmptyCampsSuccess: (state, action: PayloadAction<ICamp[]>) => {
      if (action.payload.length)
        state.emptyCamps = state.emptyCamps.concat(action.payload);
      state.hasMoreEmptyCamps = action.payload.length === 10;
      state.error = '';
    },

    getEmptyCampsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    addEmptyCampsOffset: state => {
      state.emptyCampsOffset += 10;
    },

    getEmptyCampsCntRequest: (state, action) => {
      state.emptyCampsCnt = 0;
      state.error = '';
    },

    getEmptyCampsCntSuccess: (state, action: PayloadAction<number>) => {
      state.emptyCampsCnt = action.payload;
      state.error = '';
    },

    getEmptyCampsCntFailure: (state, action: PayloadAction<Error | string>) => {
      state.emptyCampsCnt = 0;
      state.error = action.payload;
    },

    getEmptySitesRequest: (
      state,
      action: PayloadAction<IGetEmptyCampsRequestPayload>,
    ) => {
      if (action.payload.skip !== undefined)
        state.emptySitesOffset = action.payload.skip;

      if (!action.payload.skip) state.emptySites = [];
      state.error = '';
    },

    getEmptySitesSuccess: (
      state,
      action: PayloadAction<{
        camps: IZoneSiteExhibitionCamp[];
        hasNext: boolean;
      }>,
    ) => {
      if (action.payload.camps.length)
        state.emptySites = state.emptySites.concat(action.payload.camps);
      state.hasMoreEmptySites = action.payload.hasNext;
      state.error = '';
      // state.emptySitesOffset += 6;
    },

    getEmptySitesFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    resetEmptySitesOffset: state => {
      state.emptySitesOffset = 0;
      state.hasMoreEmptySites = true;
    },
    setEmptySitesOffset: (state, action: PayloadAction<number>) => {
      state.emptySitesOffset = action.payload;
    },

    getSearchCampsRequest: (
      state,
      action: PayloadAction<IGetSearchCampsRequest>,
    ) => {
      if (action.payload.skip !== undefined) {
        state.searchResultOffset = action.payload.skip;
        if (action.payload.skip === 0) state.searchResultCamps = [];
      } else {
        state.searchResultCamps = [];
      }
    },

    getSearchCampsMoreRequest: (
      state,
      action: PayloadAction<IGetSearchCampsRequest>,
    ) => {
      if (action.payload.skip !== undefined) {
        state.searchResultOffset = action.payload.skip;
        if (action.payload.skip === 0) state.searchResultCamps = [];
      }
    },

    getSearchCampsSuccess: (
      state,
      action: PayloadAction<{ data: ISearchResultCamp[]; hasNext: boolean }>,
    ) => {
      if (action.payload.data.length)
        state.searchResultCamps = (state.searchResultCamps || []).concat(
          action.payload.data,
        );
      state.hasMoreSearchResult = action.payload.hasNext;
      state.error = '';
    },

    getSearchCampsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },
    setSearchResultCampsOffset: (state, action: PayloadAction<number>) => {
      state.searchResultOffset = action.payload;
    },
    getSearchAdRequest: (
      state,
      action: PayloadAction<IGetCampSearchCampRequestPayload>,
    ) => {
      state.error = '';
      state.sortedType =
        action.payload.sort === undefined
          ? 'recommendDesc'
          : action.payload.sort;
    },

    getSearchAdSuccess: (state, action: PayloadAction<ISearchResultCamp[]>) => {
      state.searchAdList = action.payload;
      state.error = '';
    },

    getSearchAdFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    getSearchResultCountRequest: (state, action) => {},

    getSearchResultCountSuccess: (state, action: PayloadAction<number>) => {
      state.searchResultCount = action.payload;
    },

    getSearchResultCountFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.searchResultCount = undefined;
      state.error = action.payload;
    },

    resetSearchResultCamps: state => {
      state.searchResultOffset = 0;
      state.hasMoreSearchResult = true;
      state.searchResultCamps = [];
    },
  },
});

export const {
  searchRequest,
  searchSuccess,
  searchFailure,

  longTermSearchRequest,
  longTermSearchSuccess,
  longTermSearchFailure,

  longTermSearchCountRequest,
  longTermSearchCountSuccess,
  longTermSearchCountFailure,

  getCampAdRequest,
  getCampAdSuccess,
  getCampAdFailure,
  searchCamps,
  resetSearchCampsState,
  getCampsCountRequest,
  getCampsCountSuccess,
  getCampsCountFailure,
  addOffset,
  addAutoOffset,
  checkPopPath,
  getEmptyCampsRequest,
  getEmptyCampsSuccess,
  getEmptyCampsFailure,
  addEmptyCampsOffset,
  getEmptyCampsCntRequest,
  getEmptyCampsCntSuccess,
  getEmptyCampsCntFailure,
  getEmptySitesRequest,
  getEmptySitesSuccess,
  getEmptySitesFailure,
  resetEmptySitesOffset,
  setEmptySitesOffset,
  getSearchCampsRequest,
  getSearchCampsMoreRequest,
  getSearchCampsSuccess,
  getSearchCampsFailure,
  setSearchResultCampsOffset,
  getSearchAdRequest,
  getSearchAdSuccess,
  getSearchAdFailure,
  getSearchResultCountRequest,
  getSearchResultCountSuccess,
  getSearchResultCountFailure,
  resetSearchResultCamps,
} = searchSlice.actions;

export default searchSlice.reducer;
